<template>
  <div class="activity-flow-column" :class="{ few: small }">
    <ActivityFlowItem v-for="item in column.items" :key="item.id" v-slot="props" :item="item">
      <slot v-bind="props" />
    </ActivityFlowItem>
  </div>
</template>

<script>
import ActivityFlowItem from '@/modules/activity/components/ActivityFlowGraph/ActivityFlowItem';
import { onMounted, ref, getCurrentInstance } from 'vue';

export default {
  name: 'ActivityFlowColumn',
  components: { ActivityFlowItem },
  props: {
    column: { type: Object, required: true },
  },
  setup() {
    const vm = getCurrentInstance().proxy;
    const small = ref(false);

    onMounted(() => {
      if (vm.$el.clientHeight < vm.$el.parentElement.clientHeight) {
        small.value = true;
      }
    });
    return {
      small,
    };
  },
};
</script>

<style scoped lang="scss">
@import '../../style/dimensions';

.activity-flow-column {
  width: $activity-item-width + ($activity-connection-part-line-width * 2);
  padding: 16px $activity-connection-part-line-width;
  display: flex;
  flex-direction: column;
  align-items: center;

  &.few {
    position: sticky;
    top: 50px;
  }
}
</style>
