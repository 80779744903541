<template>
  <PageLayout class="container">
    <template #header>
      <div class="d-flex justify-content-between">
        <div>
          <h1>{{ $t('operationManagement.title') }}</h1>
          <p v-if="showSupplier" class="fw-bold">{{ selectedSupplierName }}</p>
        </div>
        <el-select
          v-if="!suppliersLoading && showSupplier"
          v-model="selectedSupplierId"
          size="mini"
          placement="end"
          filterable
          clearable
          @input="handleSupplierSelection"
        >
          <el-option v-for="supplier in supplierList" :key="supplier.id" :label="supplier.name" :value="supplier.id" />
        </el-select>
      </div>
    </template>
    <template #tabs>
      <Tabs :tabs="tabs" :active-tab.sync="activeTab" @click="handleTabClick" />
    </template>
    <router-view />
  </PageLayout>
</template>

<script>
import { computed, ref, getCurrentInstance, onMounted } from 'vue';
import { onBeforeRouteUpdate } from 'vue-router/composables';

import { PageLayout, Tabs } from '@/modules/core';
import { useSuppliersNew } from '@/modules/suppliers';

export default {
  components: {
    PageLayout,
    Tabs,
  },

  setup() {
    const { $route, $router, $i18n } = getCurrentInstance().proxy;
    const { suppliers, loading: suppliersLoading } = useSuppliersNew();
    const activeTab = ref(null);
    const showSupplier = ref(null);
    const tabs = [
      {
        text: $i18n.t('operationManagement.tabs.open'),
        path: 'reconciliation-open-activity',
      },
      {
        text: $i18n.t('operationManagement.tabs.closed'),
        path: 'reconciliation-closed-activity',
      },
      {
        text: $i18n.t('operationManagement.tabs.supplierCreate'),
        path: 'supplier-create-tasks',
      },
      {
        text: $i18n.t('operationManagement.tabs.balanceAlignment'),
        path: 'balance-alignment-tasks',
      },
      {
        text: $i18n.t('operationManagement.tabs.balanceBillings'),
        path: 'reconciliation-tasks',
      },

      {
        text: $i18n.t('operationManagement.tabs.billingEventValidation'),
        path: 'uncertain-billing-tasks',
      },
    ];
    const tabsWithSupplierFilter = ['reconciliation-closed-activity', 'reconciliation-open-activity'];

    const tabChange = (name) => {
      activeTab.value = tabs.findIndex((t) => t.path === name);
      showSupplier.value = tabsWithSupplierFilter.includes(name);
    };

    const supplierList = computed(() =>
      [{ name: $i18n.t('operationManagement.allSuppliersFilter'), id: null }].concat(
        suppliers.value?.slice().sort((supplierA, supplierB) => supplierA.name.localeCompare(supplierB.name))
      )
    );

    const selectedSupplierId = ref(null);

    onBeforeRouteUpdate(({ name, query }, _, next) => {
      selectedSupplierId.value = query.supplierId;
      tabChange(name);
      next();
    });

    onMounted(() => {
      tabChange($route.name);
    });

    const selectedSupplierName = computed(
      () => supplierList.value.find((s) => s.id === selectedSupplierId.value)?.name
    );

    const handleSupplierSelection = (supplierId) => $router.replace({ query: { supplierId: supplierId || null } });
    const handleTabClick = (tabIndex) => {
      const selectedTab = tabs[tabIndex];
      $router.push({ path: selectedTab.path, query: { supplierId: selectedSupplierId.value } });
    };

    return {
      showSupplier,
      supplierList,
      selectedSupplierName,
      selectedSupplierId,
      suppliers,
      suppliersLoading,
      tabs,
      activeTab,
      handleTabClick,
      handleSupplierSelection,
    };
  },
};
</script>

<style scoped>
.container {
  min-width: 1480px;
}
</style>
