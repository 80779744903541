<template>
  <PageLayout>
    <template #header>
      <div class="d-flex justify-content-between align-items-center">
        <h1>{{ $t('routes.activity') }}</h1>
        <SupplierActivityControllers
          slot="headerSide"
          :month="selectedMonth"
          @date-changed="selectedMonth = $event"
          @event-changed="selectedEvent = $event"
          @more-actions="handleMoreActions"
        />
      </div>
      <p class="fw-bold" :class="{ ['visually-hidden']: !supplier.name }">{{ subtitle }}</p>
    </template>
    <div class="table-container-hight pb-4 overflow-auto">
      <div v-if="activityLoading" class="row h-100">
        <div class="col-sm-12 my-auto">
          <div v-loading="true" />
        </div>
      </div>
      <ActivityFlowGraph v-else class="rounded border" :columns="columns" :month="selectedMonth" />
    </div>
    <EventMapModal v-if="selectedItem" :activity="selectedItem" @close="handleEventMapModalClose()" />
    <OpenIssuesModal
      v-if="issuesModalOpen"
      :range="issuesModal.range"
      :billings="issuesModal.billings"
      :orders="issuesModal.orders"
      :supplier-name="supplier.name"
      :refetch="refetch"
      :business-id="currentTenant.id"
      @close="issuesModalOpen = false"
    />
    <CreditMatchModal
      v-if="showCreditMatchModal"
      :selected-billing-id="creditMatchModalData.id"
      :default-month="creditMatchModalData.date"
      :supplier="supplier"
      :business-id="currentTenant.id"
      @close="handleCreditMatchModalClose"
    />
  </PageLayout>
</template>
<script>
import { useRoute } from 'vue-router/composables';
import { computed, ref, getCurrentInstance, watch } from 'vue';
import { DateTime } from 'luxon';

import { useSupplier } from '@/modules/suppliers/compositions/supplier';
import ActivityFlowGraph from '@/modules/activity/components/ActivityFlowGraph/ActivityFlowGraph';
import SupplierActivityControllers, { MORE_COMMANDS } from '@/modules/activity/components/SupplierActivityControllers';
import { useSelectedItem } from '@/modules/activity/store/supplier-activity-store';
import { useActivityState } from '@/modules/activity/store/activity-state';
import { PageLayout } from '@/modules/core';
import { useTenancy } from '@/modules/auth';

import { useSupplierActivity } from './compositions/supplier-activity';
import OpenIssuesModal from './components/openIssuesModal';
import CreditMatchModal from './components/creditMatchModal';

const getDefaultMonth = () => {
  const date = new Date();
  return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}`;
};

export default {
  components: {
    PageLayout,
    SupplierActivityControllers,
    ActivityFlowGraph,
    EventMapModal: () => import('@/modules/eventMapModal/EventMapModal'),
    OpenIssuesModal,
    CreditMatchModal,
  },
  setup() {
    const { $router } = getCurrentInstance().proxy;
    const { currentTenant } = useTenancy();
    const { showCreditMatchModal, selectedCredit } = useActivityState();
    const {
      params: { supplierId },
      query: { month = getDefaultMonth() },
    } = useRoute();
    const { supplier } = useSupplier({ value: supplierId });

    const selectedMonth = ref(month);
    watch(selectedMonth, (newMonth) => {
      $router.replace({ query: { month: newMonth } });
      selectedMonth.value = newMonth;
    });

    const start = computed(() => DateTime.fromFormat(selectedMonth.value, 'yyyy-MM').toISODate());
    const end = computed(() => DateTime.fromFormat(selectedMonth.value, 'yyyy-MM').endOf('month').toISODate());

    const selectedItem = useSelectedItem();

    const variables = computed(() => ({ businessId: currentTenant.value.id, supplierId, start, end }));
    const { result: columns, data, refetch, loading: activityLoading } = useSupplierActivity(variables);

    const issuesModalOpen = ref(false);

    const creditDate = ref(new Date(selectedMonth.value));
    const differencesDate = ref(new Date(selectedMonth.value));

    const creditMatchModalData = computed(() => {
      let billing = data.value.billings.find((billing) => billing.id === selectedCredit.value?.parentId);
      if (selectedCredit.value?.relatedId) {
        billing = billing.related.find((related) => related.id === selectedCredit.value?.relatedId);
      }

      return {
        id: billing?.id,
        date: billing?.date || selectedMonth.value,
      };
    });

    return {
      activityLoading,
      supplier,
      selectedMonth,
      issuesModalOpen,
      showCreditMatchModal,
      creditDate,
      differencesDate,
      refetch,
      selectedItem,
      creditMatchModalData,
      columns,
      issuesModal: computed(() => ({
        range: { from: start.value, to: end.value },
        billings: data.value.billings,
        orders: data.value.orders,
      })),
      handleEventMapModalClose() {
        refetch();
        selectedItem.value = null;
      },
      handleCreditMatchModalClose() {
        refetch();
        showCreditMatchModal.value = false;
      },
      handleMoreActions(command) {
        switch (command) {
          case MORE_COMMANDS.SHOW_ALL_ISSUES:
            issuesModalOpen.value = !activityLoading.value;
            break;

          case MORE_COMMANDS.MATCH_CREDITS:
            showCreditMatchModal.value = !activityLoading.value;
            break;

          default:
            break;
        }
      },
    };
  },
  computed: {
    subtitle() {
      const date = new Date(this.selectedMonth).toLocaleDateString(this.$i18n.locale, {
        year: 'numeric',
        month: 'long',
      });
      return `${this.supplier.name} - ${date}`;
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

.table-container-hight {
  height: calc(100vh - #{$page-layout-header-hight + $tenant-header-height});
}
</style>
