<template>
  <div class="activity-flow-columns">
    <ActivityFlowColumn v-for="(column, index) in columns" :key="index" v-slot="props" :column="column">
      <slot :name="`column-${index}-item`" v-bind="props" />
    </ActivityFlowColumn>
  </div>
</template>

<script>
import ActivityFlowColumn from '@/modules/activity/components/ActivityFlowGraph/ActivityFlowColumn';

export default {
  name: 'ActivityFlowColumns',
  components: { ActivityFlowColumn },
  props: {
    columns: { type: Array, required: true },
  },
};
</script>

<style scoped>
.activity-flow-columns {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex: 1;
  position: relative;
}
</style>
